import React from 'react';
import { graphql, useStaticQuery, Link } from "gatsby"

const Footer = () => {
	const subStackIframe = '<iframe src="https://understandingx.substack.com/embed" width="960" height="320" style="border:1px solid #EEE; background:white;" frameborder="0" scrolling="no"></iframe>';
	const Iframe = (props) => {
		return (<div className='flex items-center justify-between' dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} />);
	}
	return (
		<div>
			<div className="sticky self-center top-0 flex justify-between items-center bg-gray-200 p-2 mb-5">
				<Link to="/" target="_balnk" >gokulnk</Link>
				<Link to="/curious-about/" >readinglist</Link>
				<Link to="/notes/" target="_balnk" >notes</Link>
			</div>
			<Iframe iframe={subStackIframe} />
			<footer>
				© {new Date().getFullYear()}, Site By
				{` `}

				<Link to="https://twitter.com/gokulnk" target="_balnk" >@gokulnk</Link> 
			</footer>
		</div>
	);

}

export default Footer
