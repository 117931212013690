import React, { useState } from "react";

const SearchComponent = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleButtonClick = () => {
    const googleQuery = `https://www.google.com/search?q=site%3Agokulnk.com+${encodeURIComponent(searchTerm)}`;
    window.open(googleQuery, '_blank');
  };

  return (
    <div class="mt-8">
      <input type="text" onChange={handleInputChange} value={searchTerm} class="shadow-md focus:shadow-outline-gray border border-gray-300 rounded-lg py-2 px-4" />
      <button onClick={handleButtonClick} class="ml-4 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg">Search</button>
    </div>
  );
};

export default SearchComponent;
