import React from 'react';
import PropTypes from 'prop-types';


const Content = ({ children }) => {
    return (
        <>
            <div className="content-component self-center min-h-[90%] bg-gray-50 p-2">
                <main>{children}</main>
            </div>
        </>
    );
};

Content.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Content;