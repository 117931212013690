import React from 'react';
import PropTypes from 'prop-types';
// import { useSiteMetadata } from "../hooks/use-site-metadata"
import Header from '../components/Header';
import Content from '../components/Content';
import Footer from '../components/Footer';

import './layout.css';

const Layout = ({ children }) => {
    return (
        <>
            <div className="bg-gray-80">
                <div className="content-center w-12/12 " style={{
                    margin: `0 auto`,
                    maxWidth: 960,
                    padding: `0px 1.0875rem 1.45rem`,
                    paddingTop: 0,
                }}>
                    <Header></Header>
                    <Content children={children}></Content>
                    <Footer></Footer>
                </div>
            </div>
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;