import React from "react"
import { Link, graphql } from "gatsby"
import Layout from '../../components/Layout';
import SearchComponent from "../../components/SearchComponent";

const Home = ({
  data: {
    allFile: { edges },
  },
}) => {
  const Notes = edges.map(edge => {
    if (edge.node.childMdx && edge.node.childMdx.slug && !(("" + edge.node.childMdx.slug).includes(".trash"))) {
      return (
        <article>
          <Link to={`/notes/${edge.node.childMdx.slug}`}>
            <h1>{edge.node.name}</h1>
          </Link>
          <p>Time to Read : {edge.node.childMdx.timeToRead} mins</p>
          <p>Word Count : {edge.node.childMdx.wordCount.words}</p>
          <section>{edge.node.childMdx.excerpt}</section>
          <p>
            <br />
            <Link to={`/notes/${edge.node.childMdx.slug}`}>
              Read more
          </Link>
          </p>
          <hr />
        </article>
      );
    }
    else { return '' };
  });

  return (
  <Layout>
    <section>
      <div class="flex justify-center items-center h-full pb-4">
        <SearchComponent></SearchComponent>
      </div>
      <div class="">{Notes}</div>
    </section> 
  </Layout>)
}

export default Home

//There is some issue with using All Files. If we use that we can add created and updated time too
export const pageQuery = graphql`
  query AllNotesWithReferencesUsingFileSortByWords {
    allFile(sort: {fields: size, order: DESC}) {
      edges {
        node {
          childMdx {
            slug
            timeToRead
            wordCount {
              words
            }
            inboundReferences {
              ... on Mdx {
                id
                slug
              }
            }
            excerpt(pruneLength: 400)
          }
          birthTime
          name
          size
          prettySize
        }
      }
    }
  }
`