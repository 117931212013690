import React from 'react';
import { Link } from 'gatsby';



const Header = () => {
	return (
		<div className="sticky self-center top-0 flex justify-between items-center bg-gray-200 p-2 mb-5">
			<Link to="/" target="_balnk" >gokulnk</Link>
			<Link to="/curious-about/" >reading list</Link>
			<Link to="/notes/" target="_balnk" >notes</Link>
		</div>
	);

}

export default Header
